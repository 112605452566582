<template>
  <div>
    <b-autocomplete
      v-if="field.properties.basic.hasAutocomplete"
      :value="values[field.id]"
      :data="filteredDataArray()"
      size="is-small"
      expanded
      :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
      @input="handleUpdate($event)"
      @select="option => handleUpdate(option)"
      @blur="handleBlur()"
      v-on="fieldEvents"
    />
    <b-select
      v-else
      :value="selectedValues"
      size="is-small"
      expanded
      :multiple="field.properties.basic.multiselect"
      :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
      @input="handleUpdate($event)"
      @blur="handleBlur()"
      v-on="fieldEvents"
    >
      <option
        v-for="(choice, cIndex) in choices"
        :key="cIndex"
        :value="choice"
      >
        {{ getTranslation(`${field.id}_choice${cIndex + 1}`, choice) }}
      </option>
    </b-select>
  </div>
</template>

<script >
import { computed, onMounted } from '@vue/composition-api';
import { useLocalizationStore } from '@/store/localizationStore';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const localizationStore = useLocalizationStore();
  const {
    getTranslation
  } = localizationStore;
  const selectedValues = computed(() => props.field.properties.basic.multiselect ? (props.values[props.field.id] || '').split(',') : props.values[props.field.id]);
  const choices = computed(() => {
    if (!props.field.properties.basic.choices.length) {
      return [];
    }
    if (props.field.properties.basic.sortOptionsInAlphabeticOrder) {
      return [...props.field.properties.basic.choices].sort();
    } else {
      return props.field.properties.basic.choices;
    }
  });
  const filteredDataArray = () => {
    const {
      choices
    } = props.field.properties.basic;
    const {
      id
    } = props.field;
    return choices.filter(option => {
      if (props.values[id]) {
        return option.toString().toLowerCase().indexOf(props.values[id].toLowerCase()) >= 0;
      }
      return option;
    });
  };
  onMounted(() => {
    filteredDataArray();
  });
  const handleUpdate = value => {
    let label = '';
    if (Array.isArray(value)) {
      label = [];
      value.forEach(val => {
        label.push(getOptionLabel(val));
      });
    } else {
      label = getOptionLabel(value);
    }
    emit('update', {
      value,
      label
    });
    if (props.customEventHandler) {
      props.customEventHandler();
    }
  };
  const getOptionLabel = optionValue => {
    const index = choices.value.findIndex(choice => choice === optionValue);
    return index !== undefined ? getTranslation(`${props.field.id}_choice${index + 1}`, optionValue) : '';
  };
  const handleBlur = () => {
    emit('blur');
  };
  return {
    getTranslation,
    selectedValues,
    choices,
    filteredDataArray,
    handleUpdate,
    handleBlur
  };
};
export default __sfc_main;
</script>
