import { getSessionClient } from './sessionClient';
let translationParser = null;
export const createTranslationParser = (translationParserConfig) => {
    const sessionClient = getSessionClient();
    const parse = async (translationKey) => {
        const [moduleLanguage, appId, moduleId] = await sessionClient.read('__current_language', '__application_id', '__module_id');
        const value = await translationParserConfig.extractTranslation({
            appId,
            moduleId,
            reference: translationKey,
            langQueryParam: moduleLanguage
        });
        return value || '';
    };
    translationParser = {
        parse,
        ...translationParserConfig
    };
    return getTranslationParser();
};
export const getTranslationParser = () => {
    if (translationParser === null) {
        throw '@nuclicore/core: translation parser is not configured';
    }
    return translationParser;
};
