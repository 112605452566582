import { createSessionClient } from '../modules/sessionClient';
import { createVariableParser } from '../modules/variableParser';
import { createFormulaParser } from '../modules/formulaParser';
import { createExpressionParser } from '../modules/expressionParser';
import { createWorkflowEngine } from '../modules/workflowEngine';
import { createTranslationParser } from '../modules/translationParser';
let config = null;
export const createConfigManager = () => {
    const defineConfig = (configParams) => {
        config = { ...configParams };
    };
    const init = () => {
        if (config === null) {
            throw '@nuclicore/core: No configuration is defined in config manager for initialization.';
        }
        else {
            createSessionClient(config.sessionClient);
            createTranslationParser(config.translationParser);
            createVariableParser(config.variableParser);
            createExpressionParser();
            createFormulaParser();
            createWorkflowEngine();
        }
    };
    return {
        defineConfig,
        init
    };
};
