<template>
  <div
    class="radio-box"
  >
    <div :class="field.properties.basic.inOneLine ? 'block' : ''">
      <b-radio
        v-for="(choice, cIndex) in field.properties.basic.choices"
        :key="cIndex"
        :value="values[field.id]"
        :name="choice"
        class="is-12"
        :class="{
          'checked': values[field.id] === choice, 'column': !field.properties.basic.displayOptionsInRow
        }"
        size="is-small"
        :native-value="choice"
        @input="handleInput(choice, cIndex)"
        v-on="fieldEvents"
      >
        {{ getTranslation(`${field.id}_choice${cIndex + 1}`, choice) }}
      </b-radio>
    </div>
  </div>
</template>

<script >
import { useLocalizationStore } from '@/store/localizationStore';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const localizationStore = useLocalizationStore();
  const {
    getTranslation
  } = localizationStore;
  const handleInput = (value, index) => {
    let label = getTranslation(`${props.field.id}_choice${index + 1}`, value);
    if (value === props.values[props.field.id]) {
      value = '';
      label = '';
    }
    emit('update', {
      value,
      label
    });
    if (props.customEventHandler) {
      props.customEventHandler();
    }
  };
  return {
    getTranslation,
    handleInput
  };
};
export default __sfc_main;
</script>
